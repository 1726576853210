import "./App.css";
import CenteredCard from "./components/CenteredCard/index.jsx";

function App() {
  const childrenHere = <h1> Exemplo</h1>;
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <p>Passando um props</p>
          <CenteredCard children={childrenHere}></CenteredCard>
          <p>Escrevendo um texto SEM props</p>
          <CenteredCard>
            <h1> Exemplo 02</h1>
          </CenteredCard>
          <p>Passando uma variável SEM props</p>
          <CenteredCard>{childrenHere}</CenteredCard>
          <p>Passando um valor padrão</p>
          <CenteredCard />
        </div>
      </header>
    </div>
  );
}

export default App;
