import "./CenteredCard.css";

function CenteredCard({ children = <h1> Exemplo3</h1> }) {
  return (
    <div className="token">
      <span>{children}</span>
    </div>
  );
}

export default CenteredCard;

/*
<p>
          Convênio médico: <span>{medical_agreement}</span>
        </p>
        <p>
          Nome: <span>{name}</span>
        </p>
        <p>
          Histórico: <span>{historic}</span>
        </p>
*/
